import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { Helmet } from 'react-helmet'
import { Formik, MAudio, withFormikCompareFix } from '@mprise/react-ui'
import { useHistory } from '../../../shared/use-history'
import { useLocalState } from '../../../shared/local-state'
import { SortAndTransferForm } from './SortAndTransferForm'
import { FlashAlerts } from '../../../shared/flash-alerts'
import { SavingSwitchPanel } from '../../../shared/saving-switch-panel'
import { MutationErrorMessage } from '../../../shared/apollo'
import { ValidationIssues } from '../../../mprise-light/ValidationIssues'
import { useAppSettingsContext } from '../../../context/AppSettingsContext'
import { DialogFormik } from '../../../mprise-light/DialogFormik'
import { useApolloClient, useMutation, useQuery } from '@apollo/client'
import { STOP_TASK } from '../../../gql/mutation/statusChange/statusChange'
import { parseFloatQuantity } from '../../../shared/formats'
import { Maybe, WorkItemTemplateTaskOption } from '../../../shared/enums'
import { JobInventoryDetail } from '../../../shared/interfaces'
import { fail } from '../../../shared/typescript'
import { STOP_TIME_REG } from '../../../gql/mutation/timeReg'
import { WORKTASK_BY_ID_WITH_TASK_RESULTS } from '../../../gql/query/workItems/workTaskByIdWithTaskResults'
import { REPORT_SORT_AND_TRANSFER } from '../../../gql/mutation/reportSortAndTransferToPosition'
import { REPORT_JOB_INVENTORY_PUTAWAY } from '../../../gql/mutation/reportJobInventoryPutAway'
import { BULK_REDUCE_JOB_INVENTORY } from '../../../gql/mutation/bulkReduceJobInventory'

export interface SortAndTransferEntryForm {
  jobInventoryPutAways: Array<{
    toPosition: Maybe<{ id: string; name: string; code: Maybe<string> }>
    quantity: Maybe<number>
    quantityUnit: Maybe<string>
    sorting: Maybe<string>
    plannedQuantity: Maybe<number>
  }>
  job: Maybe<{ id: string; name: string; code: Maybe<string>; item?: Maybe<{ id: string; name?: Maybe<string> }> }>
  jobInventoryDetail: Maybe<JobInventoryDetail>
  workItemId: Maybe<string>
  workItemCosmosKey: Maybe<string>
  taskCosmosKey: Maybe<string>
  task: Maybe<any>
}

export const SortAndTransferRoute = () => {
  const h = useHistory()
  const { t } = useTranslation()
  const schema = SortAndTransferForm.useSchema()

  const alerts = FlashAlerts.useAlert()
  const apollo = useApolloClient()
  const cache = apollo.cache

  const { resource, numberFormat } = useAppSettingsContext()
  const resourceId = resource?.id ?? h.push('/')

  const taskId = useParams().taskId ?? h.push('/')

  const taskQuery = useQuery(WORKTASK_BY_ID_WITH_TASK_RESULTS, {
    variables: {
      where: [{ field: 'id', options: { eq: +taskId } }],
      filter: { mandatoryType: 'JOB_WORK_ORDER' },
    },
  })

  const task = taskQuery.data?.nworkTask
  const workItemId = task?.workItem.id
  const workItemCosmosKey = task?.workItem.cosmosKey
  const taskCosmosKey = task?.cosmosKey

  const [stopTaskMutation, stopTaskState] = useMutation(STOP_TASK)
  const [stopTimeReg] = useMutation(STOP_TIME_REG)

  const handleClose = async (autoFinished?: boolean, e?: React.FormEvent<Element>) => {
    e?.preventDefault()
    e?.stopPropagation()

    if (workItemId && taskId && !autoFinished) {
      await stopTaskMutation({
        variables: {
          workItemId: +workItemId,
          taskId: +taskId,
          currentResourceId: +resourceId,
        },
        refetchQueries: [WORKTASK_BY_ID_WITH_TASK_RESULTS],
      }).then(response => {
        stopTimeReg({
          variables: {
            workItemId: workItemCosmosKey,
            taskId: taskCosmosKey,
          },
        })
      })
    }
    h.goBack()
  }

  const [reportSortAndTransfer, reportSortAndTransferState] = useMutation(REPORT_SORT_AND_TRANSFER)
  const [reportJobInventoryPutAway, reportJobInventoryPutAwayState] = useMutation(REPORT_JOB_INVENTORY_PUTAWAY)
  const [bulkReduceJobInventory, bulkReduceJobInventoryState] = useMutation(BULK_REDUCE_JOB_INVENTORY)

  const [initialValues] = useLocalState((): SortAndTransferEntryForm => {
    const job = (task?.workItem.jobs ?? [])[0]
    return withFormikCompareFix({
      job: { id: job?.id, name: job?.name, code: job?.code },
      jobInventoryDetail: null,
      task: task,
      workItemId: workItemId,
      workItemCosmosKey: workItemCosmosKey,
      taskCosmosKey: taskCosmosKey,
      jobInventoryPutAways:
        task?.workItem.jobInventoryPutAway.map(
          (i: {
            id: any
            planned: {
              position: any
              quantity: any
              quantityUnit: string
              sorting: string
              quantityPerArea: any
              quantityPerAreaUnit: string
            }
            reported: Array<{ quantity: number }>
          }) => {
            const reportedQuantitySum = i.reported.reduce((sum, report) => sum + report.quantity, 0)
            return {
              taskResultId: i.id,
              toPosition: { id: i.planned.position.id, name: i.planned.position.name, code: i.planned.position.code },
              quantity: i.planned.quantity - reportedQuantitySum,
              quantityUnit: i.planned.quantityUnit,
              sorting: i.planned.sorting,
              plannedQuantity: i.planned.quantity,
              quantityPerArea: i.planned.quantityPerArea,
              quantityPerAreaUnit: i.planned.quantityPerAreaUnit,
            }
          },
        ) ?? [],
    })
  }, [task])
  const handleSubmit = async (form: any) => {
    let autoFinished = false
    let success = true

    if (!resourceId) {
      fail('expects resource id')
    }
    const originJobInventoryDetailId = form.jobInventoryDetail.id
    const phaseCode = task?.workItem?.phaseCode
    const taskOptions = task?.taskOptions ?? []
    const bypassJobInventory = taskOptions.includes(WorkItemTemplateTaskOption.BypassJobInventoryGh)

    if (bypassJobInventory && workItemId && task) {
      const toBeReported = (form.jobInventoryPutAways ?? []).filter((x: any) => x.quantity > 0)
      const allQuantities = []

      for (const a of toBeReported) {
        const quantity = parseFloatQuantity(a.quantity, numberFormat)
        allQuantities.push(quantity)

        await reportJobInventoryPutAway({
          variables: {
            workItemId: +workItemId,
            taskId: +task.id,
            taskPutAwayId: +a.taskResultId,
            values: {
              resourceId: +resourceId,
              positionId: +a.toPosition.id,
              sorting: a.sorting,
              quantity: quantity,
              quantityUnit: a.quantityUnit,
              quantityPerArea: a.quantityPerArea,
              quantityPerAreaUnit: a.quantityPerAreaUnit,
            },
          },
        }).then(response => {
          success &&= !!response.data
          autoFinished = !autoFinished && response.data.registerJobInventoryPutAway.autoFinished
        })
      }
      const totalQuantity = allQuantities.reduce((a, b) => a + b, 0)

      bulkReduceJobInventory({
        variables: {
          jobInventoryIdsAndQuantities: [
            {
              jobInventoryDetailId: +originJobInventoryDetailId,
              quantity: totalQuantity,
            },
          ],
        },
      })

      cache.evict({ id: 'ROOT_QUERY', fieldName: 'nworkTask' })
    } else {
      const sortAndTransferDestinations = (form.jobInventoryPutAways ?? [])
        .filter((x: any) => x.quantity > 0)
        .map((x: any) => ({
          sorting: x.sorting,
          quantity: parseFloatQuantity(x.quantity, numberFormat),
          positionId: +x.toPosition.id,
        }))

      await reportSortAndTransfer({
        variables: {
          originJobInventoryDetailId: +originJobInventoryDetailId,
          sortAndTransferDestinations: sortAndTransferDestinations,
          resourceId: +resourceId!,
          destinationPhase: phaseCode,
          workItemId: +workItemId,
          taskId: +taskId,
        },
      }).then(response => {
        success &&= !!response.data
        autoFinished = response.data.transferPositionJobInventoryDetailGH.autoFinished
      })
    }
    if (success) {
      MAudio.scanSuccess()
      alerts.push(t('SUCCESS_MESSAGE'), `success`)
    }
    if (form.workItemCosmosKey && form.taskCosmosKey && autoFinished) {
      cache.evict({ id: 'ROOT_QUERY', fieldName: 'searchWorkItems' })
      stopTimeReg({
        variables: {
          workItemId: workItemCosmosKey,
          taskId: taskCosmosKey,
        },
      })
    }
    handleClose(autoFinished)
  }

  return (
    <>
      <Helmet title={t('SORTING')} />
      <Formik.Formik enableReinitialize initialValues={initialValues} validationSchema={schema} onSubmit={handleSubmit}>
        <DialogFormik minWidth='xl' title={t('SORTING')} onClose={e => handleClose(false, e)} open={true}>
          <SavingSwitchPanel
            mutation={[
              reportSortAndTransferState,
              reportJobInventoryPutAwayState,
              bulkReduceJobInventoryState,
              stopTaskState,
            ]}
          >
            <ValidationIssues />
            <MutationErrorMessage
              mutation={[
                reportSortAndTransferState,
                reportJobInventoryPutAwayState,
                bulkReduceJobInventoryState,
                stopTaskState,
              ]}
            />
            <SortAndTransferForm />
          </SavingSwitchPanel>
        </DialogFormik>
      </Formik.Formik>
    </>
  )
}
